.wrapper{
    text-align: right;
    width: 60px;
    writing-mode: horizontal-tb;
}
.item{
    padding: 5px 2px;
    margin: 0 10px;
    margin-top: -1px;
    font-size:20px;
    font-family:PingFang-SC-Regular,PingFang-SC;
    font-weight:400;
    color:rgba(255,255,255,1);
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    line-height: 1;
    .name{
        font-size:10px;
        text-align:left;
        font-family:STZhongsong;
        color:rgba(255,255,255,1);
        line-height:13px;
    }
}