.desc{
    font-size: 10px;
}

.title{
    font-size: 12px;
}

.btn{
    width: 30px;
    height: 4px;
    margin-bottom: 10px;
}

.item{
    color:#fff;
    height: 250px;
    padding: 10px;
    z-index: 1;
    position: relative;
    display:block;
    transition:  all 1s .5s;
    perspective: 100;
    width: 37px;
    overflow: hidden;
    &.active{
        transform: translate3d(100px,0,-100px);
        z-index: 0;
        opacity: 0;
    }
    .text{
        margin-top: 15px;
        writing-mode:vertical-rl;  
        .rgb {
            writing-mode: horizontal-tb
        }  
    }
}
.wrapperOuter{
    height: 100vh;
    overflow-y: hidden;
    width: 190px;
    overflow-x: hidden;
}
.wrapper{
    &.fade{
        opacity: 0;
        filter: blur(5px);
    }
    width: 200px;
    z-index: 1;
    overflow-x: hidden;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    box-sizing: border-box;
}

.container{
    width: 100vw;
    height: 100vh;
    background-image: url(http://cdn.hicous.com/pic/yzwie.svg);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center center;
    overflow: hidden;
    transition: all 1s .3s;
}
.cmykWrapper{
    writing-mode: vertical-lr;
}
@font-face {
    font-family: HYLiGuoXingXingKaiW;
    src: url('https://cdn.hicous.com/pic/icp9r.TTF'),
}

.current{

    &.fade{
        opacity: 0;
        filter: blur(5px);
    }
    transition: all 1s;
    top: 0px;
    writing-mode: vertical-lr;
    position: absolute;
    right: 0;
    width: 150px;
    padding: 25px;
    box-sizing: border-box;
    bottom: 0;
    .currentTitle{
        width:90px;
        font-size:90px;
        font-family:HYLiGuoXingXingKaiW;
        color:rgba(255,255,255,1);
        line-height:90px;
    }
    .currentDesc{
        font-size:15px;
        padding-top: 10px;
        font-family:STZhongsong;
        color:rgba(255,255,255,1);
        line-height:20px;
    }
}


.currentCount{
    &.fade{
        opacity: 0;
        filter: blur(5px);
    }
    height: 150px;
    position: fixed;
    bottom: 10px;
    right: 10px;
}
.currentCMYK {
    height: 220px;
    position: fixed;
    bottom:0px;
    right: 10px;
}

.dialog{
    opacity: 0;
    filter: blur(5px);
    transition:  all 1s;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    text-align: center;
    color: #fff;
    &.show{
        opacity:1;
        z-index: 3;
        filter: blur(0px);
    }
    .dialogTitle{
        font-size: 80px;
        margin-top:  150px;
        font-family: HYLiGuoXingXingKaiW;
    }

    .dialogSubTitle {
        font-size:  20px;
        margin-bottom: 40px;
        font-family:  STZhongsong;
    }
    .dialogDesc {
        font-size:  16px;
        line-height: 30px;
        font-family:  STZhongsong;
    }
    .dialogClose {
        margin-top: 30px;
    }
}