body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}
a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '`';
}

.style_desc__1eF8b {
  font-size: 10px; }

.style_title__1Uery {
  font-size: 12px; }

.style_btn__1lGRO {
  width: 30px;
  height: 4px;
  margin-bottom: 10px; }

.style_item__2cL2Y {
  color: #fff;
  height: 250px;
  padding: 10px;
  z-index: 1;
  position: relative;
  display: block;
  -webkit-transition: all 1s .5s;
  transition: all 1s .5s;
  -webkit-perspective: 100;
          perspective: 100;
  width: 37px;
  overflow: hidden; }
  .style_item__2cL2Y.style_active__4cQIB {
    -webkit-transform: translate3d(100px, 0, -100px);
            transform: translate3d(100px, 0, -100px);
    z-index: 0;
    opacity: 0; }
  .style_item__2cL2Y .style_text__5IIwh {
    margin-top: 15px;
    -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl; }
    .style_item__2cL2Y .style_text__5IIwh .style_rgb__2J83g {
      -webkit-writing-mode: horizontal-tb;
              writing-mode: horizontal-tb; }

.style_wrapperOuter__1g4df {
  height: 100vh;
  overflow-y: hidden;
  width: 190px;
  overflow-x: hidden; }

.style_wrapper__1YqYA {
  width: 200px;
  z-index: 1;
  overflow-x: hidden;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  box-sizing: border-box; }
  .style_wrapper__1YqYA.style_fade__3zrVb {
    opacity: 0;
    -webkit-filter: blur(5px);
            filter: blur(5px); }

.style_container__2JR5p {
  width: 100vw;
  height: 100vh;
  background-image: url(http://cdn.hicous.com/pic/yzwie.svg);
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  overflow: hidden;
  -webkit-transition: all 1s .3s;
  transition: all 1s .3s; }

.style_cmykWrapper__1-_AN {
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr; }

@font-face {
  font-family: HYLiGuoXingXingKaiW;
  src: url("https://cdn.hicous.com/pic/icp9r.TTF"); }

.style_current__2qki9 {
  -webkit-transition: all 1s;
  transition: all 1s;
  top: 0px;
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
  position: absolute;
  right: 0;
  width: 150px;
  padding: 25px;
  box-sizing: border-box;
  bottom: 0; }
  .style_current__2qki9.style_fade__3zrVb {
    opacity: 0;
    -webkit-filter: blur(5px);
            filter: blur(5px); }
  .style_current__2qki9 .style_currentTitle__2ydC7 {
    width: 90px;
    font-size: 90px;
    font-family: HYLiGuoXingXingKaiW;
    color: white;
    line-height: 90px; }
  .style_current__2qki9 .style_currentDesc__2Fxdt {
    font-size: 15px;
    padding-top: 10px;
    font-family: STZhongsong;
    color: white;
    line-height: 20px; }

.style_currentCount__WdSOk {
  height: 150px;
  position: fixed;
  bottom: 10px;
  right: 10px; }
  .style_currentCount__WdSOk.style_fade__3zrVb {
    opacity: 0;
    -webkit-filter: blur(5px);
            filter: blur(5px); }

.style_currentCMYK__3N5gd {
  height: 220px;
  position: fixed;
  bottom: 0px;
  right: 10px; }

.style_dialog__2IKfn {
  opacity: 0;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  -webkit-transition: all 1s;
  transition: all 1s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  text-align: center;
  color: #fff; }
  .style_dialog__2IKfn.style_show__1gMWh {
    opacity: 1;
    z-index: 3;
    -webkit-filter: blur(0px);
            filter: blur(0px); }
  .style_dialog__2IKfn .style_dialogTitle__1OSxL {
    font-size: 80px;
    margin-top: 150px;
    font-family: HYLiGuoXingXingKaiW; }
  .style_dialog__2IKfn .style_dialogSubTitle__3zWbW {
    font-size: 20px;
    margin-bottom: 40px;
    font-family: STZhongsong; }
  .style_dialog__2IKfn .style_dialogDesc__2NGr4 {
    font-size: 16px;
    line-height: 30px;
    font-family: STZhongsong; }
  .style_dialog__2IKfn .style_dialogClose__26QN4 {
    margin-top: 30px; }

.MainRGB_wrapper__3F1Em {
  text-align: right;
  width: 60px;
  -webkit-writing-mode: horizontal-tb;
          writing-mode: horizontal-tb; }

.MainRGB_item__bSs8q {
  padding: 5px 2px;
  margin: 0 10px;
  margin-top: -1px;
  font-size: 20px;
  font-family: PingFang-SC-Regular,PingFang-SC;
  font-weight: 400;
  color: white;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  line-height: 1; }
  .MainRGB_item__bSs8q .MainRGB_name__1Sj0O {
    font-size: 10px;
    text-align: left;
    font-family: STZhongsong;
    color: white;
    line-height: 13px; }

.MainCMYK_svg__3Ys3D {
  -webkit-transition: all .3s;
  transition: all .3s; }

